import React from 'react';
import useStorage from 'src/utils/useStorage';
import Context from './Context';

const StoreProvider = ({ children }) => {
  const [token, setToken] = useStorage('token');
  const [user, setUser] = useStorage('user');

  return (
    <Context.Provider
      value={{
        token,
        setToken,
        user,
        setUser
      }}
    >
      {children}
    </Context.Provider>
  )
}


export default StoreProvider;
