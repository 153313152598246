import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import StoreProvider from './components/Store/Provider';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login/Login'));
const Logout = React.lazy(() => import('./views/Login/Logout'));

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <StoreProvider>
              <Switch>
                <Route exact path="/login" render={props => <Login {...props}/>} />
                <Route exact path="/logout" render={props => <Logout {...props}/>} />
                <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
              </Switch>
            </StoreProvider>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
